import {Autocomplete, TextField} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useApi} from "@plumeuk/shapeshift-identity";
import {useEffect, useState} from "react";
import {IUserEntity} from "@plumeuk/shapeshift-types";

interface IProps {
	assessorId?: number,
	onChange: (e?: number) => void
}

const useStyles = makeStyles()((theme) => ({
	assessorAutoCompleteInput: {
		width: 250
	},
	assessorInput: {
		padding: 0
	}
}));

export const AssessorAutoCompleteInput: React.FC<IProps> = ({assessorId, onChange}) => {
	const {classes} = useStyles();
	const [assessorSearch, setAssessorSearch] = useState<string>("");
	const [assessors, searchAssessors, setAssessors] = useApi<IUserEntity[]>();

	useEffect(() => {
		if(assessorSearch.length > 0)
			searchAssessors({url: "/api/user/searchAssessors", data: {search: assessorSearch}})
		else
			setAssessors([])
	}, [assessorSearch])

	const options = (assessors?.data ?? []).length === 0 ? [{label: "Start typing to find assessor", id: -1}] : (assessors?.data ?? []).map(e => ({label: e.email, id: e.id}));

	return (

		<Autocomplete<{id: number, label: string}>
			value={options.find(e => e.id === assessorId) ?? null}
			getOptionLabel={e => {
				return e.label;
			}}
			getOptionDisabled={(option) =>
				option.id < 0
			}
			isOptionEqualToValue={(e, v) => e?.id === v?.id}
			onChange={(_event, newValue) => {
				onChange(newValue?.id ?? undefined);
			}}
			inputValue={assessorSearch ?? ""}
			onInputChange={(_event, newInputValue) => {
				setAssessorSearch(newInputValue);
			}}
			id="assessor-search"
			options={options}
			className={classes.assessorAutoCompleteInput}
			renderInput={(params) => <TextField {...params} className={classes.assessorInput} label="Assessor" />}
		/>

	);
}