import React, {useContext, useEffect, useState} from "react";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {makeStyles} from "tss-react/mui";
import {Typography, Button, Box, Table, TableBody, TableRow, TableCell} from "@mui/material";
import {Modal} from "@plumeuk/shapeshift-common/modal";
import {ICourse, IUserSlim} from "@plumeuk/shapeshift-types";
import {AssessorAutoCompleteInput} from "./AssessorAutoCompleteInput";
import {IPortfolio} from "../../types/portfolio/IPortfolio";

export interface IProps {
	open?: boolean,
	currentAssessor?: IUserSlim,
	course: Pick<ICourse, "slug" | "title" | "id">,
	portfolio?: Pick<IPortfolio, "slug" | "title" | "id">,
	learner?: IUserSlim,
	onClose: (reloadRequired?: boolean) => void,
}

const useStyles = makeStyles()(
	(theme) => ({
		videoUploadModal: {},
		container: {
			display: "flex",
			gap: "40px",
			flexDirection: "column"
		},
		body: {},
		header: {
			display: "flex",
			justifyContent: "space-between !important"
		},
		title: {
			flexGrow: 2
		},
		fullScreen: {
			paddingRight: "20px",
			cursor: "pointer",
			"& span": {
				marginLeft: "10px"
			}
		},
		errorMessage: {
			color: theme.palette.error.main
		},
		table: {
			margin: 0,
			padding: 0,
			marginBottom: theme.spacing(5),
			width: "fit-content",
			"& p": {
				fontSize: "18px"
			},
			"& td:first-child": {
				fontWeight: 600,
				paddingLeft: 0
			}
		}
	})
);


const AssignAssessorModal: React.FunctionComponent<IProps> = ({open, onClose, currentAssessor, course, portfolio, learner}) => {
	const {classes} = useStyles();
	const [assessorId, setAssessorId] = useState(currentAssessor?.id)
	const [assignAssessorResult, assignAssessor] = useApi();
	const {notify} = useContext(NotificationContext)

	const handleClose = (): void => {
		onClose();
	};

	const handleSave = (): void => {
		if(!course?.slug || !portfolio?.slug || !learner?.id){
			notify("", "This portfolio enrollment is invalid and cannot be assigned an assessor", INotificationSeverity.error, 5000)
			return;
		}

		assignAssessor({
			url: `/api/portfolios/review/${course.slug}/${portfolio.slug}/${learner.id}/assign/${assessorId}`,
			method: "PUT"
		})
	}

	useEffect(() => {
		if(assignAssessorResult.data !== undefined){
			if(assignAssessorResult.data){
				onClose(true);
				notify("", "Successfully assigned assessor to portfolio", INotificationSeverity.success, 5000)
			}
			else
				notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000)
		}
	}, [assignAssessorResult.data])

	if (!open) return null;

	const getName = (user: IUserSlim | undefined): string | null => {
		if(!user)
			return null;
		let result = user.firstname ?? "";
		result += (result?.length > 0 ? " " : "") + user.lastname;
		if(result?.length <= 1)
			return user.email ?? "";
		return result;
	}

	return (
		<Modal
			open={true}
			className={classes.videoUploadModal}
			onClose={handleClose}
			footerButtons={[
				<Button key={1} onClick={handleClose}>
					Cancel
				</Button>,
				(assessorId !== currentAssessor?.id) ? (
					<Button key={2} onClick={handleSave}>
						Save
					</Button>
				) : <></>
			]}
		>
			<Box className={classes.container}>
				<Box className={classes.header}>
					<Typography variant="h3" className={classes.title} id="title">
						Assign Assessor
					</Typography>
				</Box>
				<Box className={classes.body}>
					<Table className={classes.table}>
						<TableBody>
							<TableRow>
								<TableCell>Portfolio</TableCell>
								<TableCell>{portfolio?.title}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Course</TableCell>
								<TableCell>{course?.title}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Learner</TableCell>
								<TableCell>{getName(learner)}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Current Assessor</TableCell>
								<TableCell>{getName(currentAssessor)?? "none"} </TableCell>
							</TableRow>
						</TableBody>
					</Table>

					<AssessorAutoCompleteInput assessorId={assessorId} onChange={e => setAssessorId(e)} />

				</Box>
			</Box>
		</Modal>
	);
};

export default AssignAssessorModal;