import {PageBase} from "../../components/pageBase/pageBase";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {MobileSideMenu} from "../../components/MobileSideMenu";
import AssessmentDashboardDataProvider from "../../providers/AssessmentDashboardDataProvider";
import {Box, LinearProgress, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip} from "@mui/material";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {useNavigate} from "react-router-dom";
import {getStatusText} from "./getStatusText";
import {IPortfolioAttemptStatus} from "../../types/portfolio/IPortfolioAttempt";
import {makeStyles} from "tss-react/mui";
import {LearnerAutoCompleteInput} from "./LearnerAutoCompleteInput";
import {CourseAutoCompleteInput} from "./CourseAutoCompleteInput";
import {palette} from "../../constants";
import {useContext, useRef, useState} from "react";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {AssessorAutoCompleteInput} from "./AssessorAutoCompleteInput";
import {Add, Edit, Error} from "@mui/icons-material";
import AssignAssessorModal from "./AssignAssessorModal";
import {IPortfolioAdminEntry} from "../../types/portfolio/IPortfolioAdminEntry";

const useStyles = makeStyles()((theme) => ({
	seachTermInput: {
		padding: 0,
		flexGrow: 3
	},
	dashboardHeader: {
		marginTop: "20px"
	},
	searchContainer: {
		display: "flex",
		gap: "15px",
		marginBottom: "15px",
		background: "transparent"
	},
	assessmentDashboardContainer: {
		paddingBottom: "90px"
	},
	tableHead: {
		[theme.breakpoints.down("md")]: {
			display: "none"
		}
	},
	mobileLabel: {
		fontWeight: "600",
		display: "none",
		margin: "2px 0",
		[theme.breakpoints.down("md")]: {
			display: "block"
		}
	},
	dashboardAssessment: {
		border: "1px solid #e3e3e3",
		borderRadius: "15px",
		display: "block",
		"& td": {
			"&:nth-last-child(1)": {
				[theme.breakpoints.up("md")]: {
					textAlign: "right"
				}
			}
		},
		[theme.breakpoints.down("md")]: {
			"& tr, & td, & tbody": {
				display: "block",
				height: "auto",
				textAlign: "left"
			},
			"& tr": {
				"&:nth-child(even)": {
					backgroundColor: palette.pastelBlue
				},
				"&:hover": {
					background: "inherit"
				}
			}
		}
	},
	assessorCell: {
		display: "flex",
		gap: "5px",
		borderRadius: "5px",
		padding: "4px",
		border: "1px solid " + theme.palette.grey[200],
		background: theme.palette.grey[100],
		"&:hover": {
			background: theme.palette.grey[300],
			"& svg": {
				opacity: 1
			}
		}
	},
	editIcon: {
		transition: "all .1s ease-in-out",
		opacity: 0,
		width: "20px",
		color: theme.palette.primary.main
	}
}));

export const AssessmentDashboardPage: React.FC = () => {
	const mq = useMq();
	const navigate = useNavigate();
	const {user} = useContext(AuthContext);
	const {classes} = useStyles();
	const [assessorAssignModal, setAssessorAssignModal] = useState<{open: boolean, entry: IPortfolioAdminEntry}>();
	const assessorAssignModalOpenContainerRef = useRef<HTMLElement>();

	const handleRowClick = (e: any, row: IPortfolioAdminEntry): void => {
		if(!assessorAssignModalOpenContainerRef.current?.contains(e.target))
			navigate(`/assessment-dashboard/${row.course.slug}/${row.portfolio.slug}/${row.learner.id}`)
	}

	return (
		<PageBase sideBarContent={mq.mobile ? <MobileSideMenu /> : undefined}>
			<AssessmentDashboardDataProvider>
				{({assessmentDashboardData, apiResult, getAssessmentDashboardData}, {setPage, setPageSize, setSearchTerm, setLearnerId, setAssessorId, setCourseSlug}, {page, pageSize, searchTerm, learnerId, assessorId, courseSlug}) => <>
					<Box className={classes.assessmentDashboardContainer}>
						<CenterTile className={classes.dashboardHeader} style={{maxWidth: "760px"}} title="Assessment Dashboard" />
						<Paper className={classes.searchContainer}>
							<TextField
								id="search"
								className={classes.seachTermInput}
								label="Search term"
								value={searchTerm}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setSearchTerm(event.target.value);
								}}
							/>
							<LearnerAutoCompleteInput learnerId={learnerId} onChange={e => setLearnerId(e)} />
							{(user?.isAuditor || user?.isRegulatedAssessmentAdmin) && <AssessorAutoCompleteInput assessorId={assessorId} onChange={e => setAssessorId(e)} />}
							<CourseAutoCompleteInput courseSlug={courseSlug} onChange={e => setCourseSlug(e)} />
						</Paper>
						<Table className={classes.dashboardAssessment}>
							<TableHead className={classes.tableHead}>
								<TableRow>
									<TableCell sx={{height: "30px"}} component="th">Learner</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Portfolio</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Status</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Course</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Last Submission Date</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Submissions</TableCell>
									<TableCell sx={{height: "30px"}} component="th">Audits</TableCell>
									{(user?.isAuditor || user?.isRegulatedAssessmentAdmin) && <TableCell sx={{height: "30px"}} component="th">Assessor</TableCell>}
								</TableRow>
							</TableHead>
							<TableBody>
								{assessorAssignModal?.open &&
									<AssignAssessorModal
										open
										currentAssessor={assessorAssignModal?.entry?.assessor}
										onClose={(reload) => {setAssessorAssignModal(undefined); if(reload) getAssessmentDashboardData()}}
										course={assessorAssignModal?.entry?.course}
										portfolio={assessorAssignModal?.entry?.portfolio}
										learner={assessorAssignModal?.entry?.learner}
									/>}
								{apiResult.isLoading ? <LinearProgress /> : assessmentDashboardData?.data?.map(e => (
									<TableRow sx={{cursor: "pointer"}} key={e.portfolio.id} hover onClick={x => handleRowClick(x, e)}>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Learner
											</Box>
											{(!e.learner?.firstname || !e.learner.lastname) ? e.learner?.email : `${e.learner?.firstname} ${e.learner?.lastname}`}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Portfolio
											</Box>
											{e.portfolio.title}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Status
											</Box>
											{getStatusText(e.portfolio.status as IPortfolioAttemptStatus)}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Course
											</Box>
											{e.course.title}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Last submission
											</Box>
											{e.portfolio?.lastSubmissionDate ? new Date(e.portfolio.lastSubmissionDate).toLocaleDateString() : ""}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Submissions
											</Box>
											{e.submissions}
										</TableCell>
										<TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Audits
											</Box>
											{e.audits}
										</TableCell>
										{(user?.isAuditor || user?.isRegulatedAssessmentAdmin) && <TableCell sx={{height: "30px"}}>
											<Box component="span" className={classes.mobileLabel}>
												Assessor
											</Box>
											<Box className={classes.assessorCell} ref={assessorAssignModalOpenContainerRef} onClick={(x) => {
												x.stopPropagation();
												setAssessorAssignModal({open: true, entry: e})}
											}>
												{(!e.assessor?.firstname || !e.assessor.lastname) ? e.assessor?.email : `${e.assessor?.firstname} ${e.assessor?.lastname}`}
												{!e.assessor?.id && <Tooltip title="No Assessor Assigned"><Error sx={theme => ({color: theme.palette.error.main})}/></Tooltip>}
												{!e.assessor?.id ? <Add /> : <Edit className={classes.editIcon}/>}
											</Box>
										</TableCell>}
									</TableRow>))}
								{(!apiResult.isLoading && !assessmentDashboardData?.data.length) && (
									<TableRow>
										<TableCell sx={{height: "30px", textAlign: "center"}} colSpan={5}>No data found</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
						<Box sx={{display: "flex", mt: 2, alignItems: "center"}}>
							<div>
								<Select
									value={pageSize}
									label="Page Size"
									onChange={(e) => {
										setPageSize(typeof e.target.value === "string" ? parseInt(e.target.value) : e.target.value)
									}}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={20}>20</MenuItem>
									<MenuItem value={50}>50</MenuItem>
								</Select>
							</div>
							<Pagination size="large" page={page} count={Math.ceil((assessmentDashboardData?.totalCount ?? 0) / pageSize)} onChange={(_, p) => setPage(p)} />
						</Box>
					</Box>
				</>}
			</AssessmentDashboardDataProvider>
		</PageBase>
	);
}