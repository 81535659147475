import {Box, BoxProps} from "@mui/material";
import React, {FC, ReactNode, cloneElement, isValidElement, useContext, useEffect, useState} from "react";
import {MobileHeader, NavBar, SideMenu} from "@plumeuk/shapeshift-common/pageBase";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {useLocation, useMatch, useNavigate} from "react-router-dom";
import {palette, sideMenuWidth, toolBarHeight} from "../../constants";
import {ModuleNavBar} from "../moduleNavBar";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import EditUserDetailsForm from "../editUserDetailsForm/editUserDetailsForm";

interface ICustomProps {
	children: React.ReactNode,
	contentWidth?: string,
	sideBarContent?: ReactNode,
	disableSideBar?: boolean
}

interface IStyleProps {
	sideMenuOpen: boolean,
	hasNavBar: boolean,
	contentWidth?: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {sideMenuOpen, hasNavBar, contentWidth}) => ({
	pageBase: {
		height: "100%",
		"& [ class*='bellIconContainer']": {
			display: "none"
		}
	},
	navBar: {
		"& [ class*='menuIcon']": {
			color: palette.black
		},
		"& [ class*='navBarLogo']": {
			width: 0,
			"& img": {
				maxWidth: "300px"
			}
		},
		"& [ class*='pageBaseProfile'] p": {
			color: palette.black
		},
		"& button": {
			fontSize: "20px",
			fontWeight: 400
		}
	},
	background: {
		backgroundColor: theme.palette.background.default,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: "-1",
		position: "fixed"
	},
	navBarSpacer: {
		width: "100%",
		height: toolBarHeight
	},
	contentContainer: {
		width: sideMenuOpen ? `calc(100% - ${sideMenuWidth})` : "100%",
		position: "absolute",
		overflowX: "hidden",
		transition: "all .3s ease-in-out",
		left: sideMenuOpen ? sideMenuWidth : "0px",
		boxSizing: "border-box",
		overflowY: "auto",
		padding: "25px",
		top: hasNavBar ? toolBarHeight : 0,
		height: hasNavBar ? `calc(100% - ${toolBarHeight})` : "100%",
		[theme.breakpoints.down("md")]: {
			left: 0,
			width: "100%"
		}
	},
	sideMenu: {
	},
	content: {
		[theme.breakpoints.up("sm")]:{
			height: "100%"
		},
		minHeight: "100%",
		boxSizing: "border-box",
		width: "100%",
		maxWidth: contentWidth ?? "1000px",
		margin: "auto"
	}
}));

type IProps = ICustomProps & Omit<BoxProps, "children">;
type pages = "Courses" | "Groups dashboard" | "Assessment dashboard"; //>"Library" 

type routeCallback = {
	[key in pages]: string;
};

const routesMap: routeCallback = {
	"Courses": "/courses",
	// "Library": "/library",
	"Groups dashboard": "/groups-dashboard",
	"Assessment dashboard": "/assessment-dashboard"
}

export const PageBase: FC<IProps> = ({sideBarContent, disableSideBar, contentWidth, children, className, ...props}) => {
	const mq = useMq();
	const {user} = useContext(AuthContext);
	const pageIsMyLearning = useMatch("/courses");
	const location = useLocation();
	const [sideMenuOpen, setSideMenuOpen] = useState(!disableSideBar && mq.desktop);
	const {classes, cx} = useStyles({sideMenuOpen, hasNavBar: true, contentWidth});
	const navigate = useNavigate();
	const [tabs, setTabs] = useState<pages[]>([]);
	const logo = mq.desktop ? "/logo.svg" : "/emblem.svg"

	useEffect(() => setSideMenuOpen(!disableSideBar && mq.desktop), [disableSideBar, mq.desktop]);

	useEffect(() => {
		if(!user) return;

		const tabs: pages[] = ["Courses"]; //>, "Library"

		if(user.isOwner)
			tabs.push("Groups dashboard");

		if(user.isAuditor || user.isAssessor || user.isRegulatedAssessmentAdmin)
			tabs.push("Assessment dashboard");

		setTabs(tabs);
	}, [user])

	let pageSelected: pages | undefined = undefined;
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if(location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})

	const handlePageSelect = (page: pages): void => {
		navigate(routesMap[page]);
	}

	const handleContentClick = (): void => {
		if(!mq.desktop && sideMenuOpen) setSideMenuOpen(false);
	}

	return (
		<Box className={cx(classes.pageBase, className)} {...props}>
			{!mq.mobile &&
				<NavBar<pages>
					className={classes.navBar}
					tabs={tabs}
					onBurgerAction={() => setSideMenuOpen(prev => !prev)}
					onPageSelect={handlePageSelect}
					pageSelected={pageSelected}
					logoPath={logo}
					disableSideBar={disableSideBar}
					editUserDetailsForm={EditUserDetailsForm as any}
				/>}
			{mq.mobile &&
				<ModuleNavBar
					onBurgerAction={() => setSideMenuOpen(prev => !prev)}
				/>}
			<SideMenu
				className={classes.sideMenu}
				open={sideMenuOpen}
				width={sideMenuWidth}
				editUserDetailsForm={EditUserDetailsForm as any}
			>
				{isValidElement(sideBarContent) ? cloneElement(sideBarContent, {...sideBarContent?.props ?? {}, setSideMenuOpen}) : undefined}
			</SideMenu>
			<Box className={classes.contentContainer} onClick={handleContentClick}>
				{mq.mobile && pageIsMyLearning && <MobileHeader editUserDetailsForm={EditUserDetailsForm as any}/>}
				<Box className={classes.content}>
					{children}
				</Box>
			</Box>
			<Box className={classes.background} />
		</Box>
	);
};
