import {FC, useState} from "react";
import {useDebounce} from "@plumeuk/shapeshift-common/hooks";
import {IPortfolioAdminEntry} from "../types/portfolio/IPortfolioAdminEntry";
import {IUsePortfolioAdminReviewEntriesProps, usePortfolioAdminReviewEntries} from "../hooks/usePortfolioAdminReviewEntries";
import {IPaginatedResponse} from "@plumeuk/shapeshift-types";

type S = IPaginatedResponse<IPortfolioAdminEntry[]>;

interface IValues {
	page: number,
	pageSize: number,
	searchTerm: string,
	learnerId?: number,
	assessorId?: number,
	courseSlug?: string
}

interface ISetters {
	setPageSize: React.Dispatch<React.SetStateAction<number>>,
	setPage: React.Dispatch<React.SetStateAction<number>>,
	setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
	setLearnerId: React.Dispatch<React.SetStateAction<number | undefined>>,
	setAssessorId: React.Dispatch<React.SetStateAction<number | undefined>>,
	setCourseSlug: React.Dispatch<React.SetStateAction<string | undefined>>
}

export interface IProps {
	children?: (e: IUsePortfolioAdminReviewEntriesProps, setters: ISetters, values: IValues) => JSX.Element,
	data?: S,
}

export const AssessmentDashboardDataProvider: FC<IProps> = ({children, data}) => {
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(10)
	const [searchTerm, setSearchTerm] = useState("")
	const [courseSlug, setCourseSlug] = useState<string>()
	const [learnerId, setLearnerId] = useState<number>()
	const [assessorId, setAssessorId] = useState<number>()
	const searchTermDebounced = useDebounce(searchTerm, 500)
	const dataHook = usePortfolioAdminReviewEntries(page, pageSize, searchTermDebounced, learnerId, assessorId, courseSlug, data)
	const {assessmentDashboardData} = dataHook;

	return children?.(dataHook, {setPage, setPageSize, setSearchTerm, setLearnerId, setAssessorId, setCourseSlug}, {page, pageSize, searchTerm, learnerId, assessorId, courseSlug})
		?? <div>Please implement children to handle AssessmentDashboard {(assessmentDashboardData?.data.length ?? 0) > 0 ? "...loading" : ""}</div>
}

export default AssessmentDashboardDataProvider;