import {PortfolioDataProvider} from "../../../providers/PortfolioDataProvider";
import {FC, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {Box, LinearProgress} from "@mui/material";
import {PortfolioQuestions} from "./PortfolioQuestions/PortfolioQuestions";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {PortfolioStatusBanner} from "./PortfolioStatusBanner";
import {ModuleNotAvailable, ModuleTab, ModuleTabs} from "@plumeuk/shapeshift-common/module";
import {CommentInput, CommentThread, CommentsSection} from "@plumeuk/shapeshift-common/comments";
import {IPortfolioAttempt, IPortfolioAttemptStatus} from "../../../types/portfolio/IPortfolioAttempt";
import {IPortfolioAnswer} from "../../../types/portfolio/IPortfolio";
import {getAllFontStyles} from "../../../templates/defaultTheme";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {CurriculumContext} from "../../../contexts/curriculumContext";


const useStyles = makeStyles()((theme) => ({
	portfolioPage: {
		paddingTop: "32px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	centeredContainer: {
		paddingBottom: "100px",
		maxWidth: "900px"
	},
	title: {
		marginBottom: "40px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		}
	},
	tabs: {
		marginBottom: "50px",
		position: "relative",
		overflow: "visible",
		"&:after": {
			width: "200vw",
			left: "-50%",
			position: "absolute",
			bottom: 0,
			background: "#d1d1d1",
			height: "1px",
			content: "''"
		}
	},
	tab: {
		color: "#A8A8A8",
		fontSize: "18px"
	},
	content: {
		...getAllFontStyles(theme),
		marginBottom: "40px",
		"& p": {
			lineHeight: 1.5,
			fontSize: "18px"
		}
	},
	activeTab: {
		color: "#2A2A2F !important"
	}
}));

export const PortfolioPage: FC = () => {
	const {classes} = useStyles();
	const {notify} = useContext(NotificationContext);
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const {courseSlug, moduleSlug: portfolioSlug} = useParams();
	const {curriculumState: curriculum} = useContext(CurriculumContext);
	const [updatedPortfolioData, updatePortfolio] = useApi<IPortfolioAttempt, FormData>()
	const [lastAttemptApi] = useApi<IPortfolioAttempt>(`/api/${courseSlug}/portfolios/${portfolioSlug}/result`)

	const handleSubmit: (portfolioAnswers: IPortfolioAnswer[]) => void = (portfolioAnswers) => {
		const payloadFormData = new FormData();
		const answers: any[] = [];
		portfolioAnswers.forEach((answer) => {
			answer.files.map((fileInfo) => fileInfo.file).forEach((f) => {
				payloadFormData.append("files", f, f.name)
			})
			answers.push(
				{
					files: answer.files.map((fileInfo) => fileInfo.name),
					questionType: "portfolioFilesUpload",
					questionId: answer.questionId
				}
			);
		})
		payloadFormData.append(
			"answers",
			JSON.stringify(answers))
		updatePortfolio({
			method: "POST",
			url: `/api/${courseSlug}/portfolios/${portfolioSlug}/questions`,
			data: payloadFormData,
			config: {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			}
		})
	}

	useEffect(() => {
		if(lastAttemptApi.data)
			window.scrollTo(0, 0);
	}, [lastAttemptApi])


	useEffect(() => {
		if(updatedPortfolioData.data?.status === "awaiting-review"){
			notify("Your submission is under review, please return later to review feedback", "Submitted!", INotificationSeverity.success, 8000);
		}
	}, [updatedPortfolioData])

	const lastAttempt = updatedPortfolioData?.data ?? lastAttemptApi?.data;
	const portfolioStatus: IPortfolioAttemptStatus | undefined = lastAttempt?.status

	return (
		<Box className={classes.portfolioPage}>
			<PortfolioDataProvider courseSlug={courseSlug} portfolioSlug={portfolioSlug} curriculum={curriculum}>
				{({portfolio, apiResult}) => <>
					{(apiResult.isLoading) && <LinearProgress />}
					{apiResult.statusCode === 403 &&
						<ModuleNotAvailable>
							{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
						</ModuleNotAvailable>
					}
					{apiResult.statusCode === 200 && <>
						<CenteredContainer className={classes.centeredContainer}>
							<ModuleTabs tabSelected={tabSelected} onChange={tab => setTab(tab)} className={classes.tabs} moduleTabs={[{label: "Portfolio", value: "overview"}, {label: "Messages and feedback", value: "comments"}]}/>

							<Box style={tabSelected === "overview" ? {} : {display: "none"}}>
								<PageTitle className={classes.title} title={portfolio?.title} subtitle={portfolio?.subtitle} />
								<WYSIWYG className={classes.content}>
									{portfolio?.content ?? ""}
								</WYSIWYG>

								{portfolioStatus && <PortfolioStatusBanner status={portfolioStatus} />}

								{(courseSlug && portfolioSlug) && <PortfolioQuestions courseSlug={courseSlug} portfolioSlug={portfolioSlug} onSubmit={handleSubmit} attempt={lastAttempt} />}
								{(updatedPortfolioData?.isLoading) && <LinearProgress />}
							</Box>
							<CommentsSection
								style={(tabSelected === "comments") ? {} : {display: "none"}}
								courseSlug={courseSlug}
								moduleType={"portfolio" as any}
								moduleSlug={portfolioSlug}
								componentOverrides={{
									commentThread: (e) => <CommentThread {...e} componentOverrides={{
										commentInput: (e) => <CommentInput {...e} placeholder="Send private reply"/>
									}} />
								}}
							/>
						</CenteredContainer>

					</>}
				</>}
			</PortfolioDataProvider>
		</Box>
	);
}